import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import HeroGeneric from "../components/HeroGeneric";
import Layout from "../components/layout";
import HelpArticle from "../components/HelpArticle";
import AccordionMulti from "../components/AccordionMulti";

export default function HelpSingle({ data }) {
	const {
		heroTitle,
		breadcrumbText,
		contentText,
		downloads,
		faqsTitle,
		faqsItems,
		faqsTitle2,
		faqsItems2,
		metaTitle,
		metaDescription,
		metaShareImage,
	} = data.craft.entry ?? [];

	let breadcrumb = [
		{
			url: "/help-support",
			text: "Help & Support",
		},
		{
			text: breadcrumbText,
		},
	];

	const faqs = [
		{
			title: faqsTitle,
			items: faqsItems,
		},
		{
			title: faqsTitle2,
			items: faqsItems2,
		},
	];

	return (
		<Layout className="layout--helpSingle">
			<SEO
				title={metaTitle}
				description={metaDescription}
				image={metaShareImage}
			/>

			<HeroGeneric
				title={heroTitle}
				breadcrumb={breadcrumb}
				colorTheme="dark"
				nextTheme="light"
			/>

			<HelpArticle content={contentText} copies={downloads} />

			<AccordionMulti groups={faqs} />
		</Layout>
	);
}

export const pageQuery = graphql`
	query helpSupport($id: CraftCMS_QueryArgument!) {
		craft {
			entry(id: [$id]) {
				slug
				uri
				title
				... on CraftCMS_helpSupport_helpSupport_Entry {
					breadcrumbText
					heroTitle
					contentText
					downloads {
						... on CraftCMS_downloads_downloadsItem_BlockType {
							id
							downloadsItemTitle
							downloadsItemAsset {
								... on CraftCMS_resources_Asset {
									title
									url
								}
							}
						}
					}
					faqsTitle
					faqsItems {
						... on CraftCMS_faqsItems_faqsItem_BlockType {
							id
							faqsItemTitle
							faqsItemText
						}
					}
					faqsTitle2
					faqsItems2 {
						... on CraftCMS_faqsItems2_faqsItem_BlockType {
							id
							faqsItemTitle
							faqsItemText
						}
					}
					metaTitle
					metaDescription
					metaShareImage {
						filename
					}
				}
			}
		}
	}
`;
