import React from "react";
import styled from "@emotion/styled";
import Section, { RichText } from "./elements/Section";
import AccordionItem from "./AccordionItem";
import PropTypes from "prop-types";
import {
	container,
	gridColumnByMargin,
	screen,
	spacingRestricted,
} from "../styles/mixins";
import Intro from "./elements/Intro";

const ContainerEl = styled.div`
	${container()};
	${spacingRestricted("padding-bottom", "l")};
`;

const AccordionEl = styled.div`
	${gridColumnByMargin(4)};
	${spacingRestricted("margin-bottom", "xl")};

	&:last-child {
		margin-bottom: 0;
	}

	@media ${screen("md")} {
		${gridColumnByMargin(11)};
	}
`;

const IntroEl = styled(Intro)`
	@media ${screen("md")} {
		${spacingRestricted("margin-bottom", "l")};
	}
`;

const AccordionMulti = ({ className, groups, colorTheme = "light" }) =>
	groups?.length ? (
		<Section colorTheme={colorTheme}>
			<ContainerEl>
				{groups?.map((group) => (
					<AccordionEl className={className} colorTheme={colorTheme}>
						<IntroEl title={group.title} />
						{group.items?.map((item, i) => (
							<AccordionItem
								heading={item.faqsItemTitle}
								key={i}
								colorTheme={colorTheme}
								mainTitle={group.title}>
								<RichText text={item.faqsItemText} small={true} />
							</AccordionItem>
						))}
					</AccordionEl>
				))}
			</ContainerEl>
		</Section>
	) : null;

AccordionMulti.propTypes = {
	colorTheme: PropTypes.string,
	groups: PropTypes.array,
};

AccordionMulti.defaultProps = {
	colorTheme: "light",
	groups: [],
};

export default AccordionMulti;
